<script setup lang="ts">
import { Popup } from '@genoch/genoch-ui-web';

defineProps({
  offset: {
    type: String,
    default: '0px'
  }
});

const userStore = useUserStore();
const { user } = storeToRefs(userStore);

const profileButton = ref<HTMLElement | null>(null);
const profileButtonRect: Ref<{ x: number; y: number, width: number } | null> = ref(null);
let profileOptionsDismissTimer: NodeJS.Timeout | null = null;
function showProfileOptions(isHovering: boolean) {
  // Clear the timer if it's already running
  if (profileOptionsDismissTimer) {
    clearTimeout(profileOptionsDismissTimer);
  }

  // If we're not hovering, set a timer to clear the profile button rect
  if (!isHovering) {
    profileOptionsDismissTimer = setTimeout(() => {
      profileButtonRect.value = null;
    }, 100);
    return;
  }

  if (!profileButton.value || profileButtonRect.value) {
    return;
  }

  // If we are hovering, set the profile button rect
  const rect = profileButton.value.getBoundingClientRect();
  profileButtonRect.value = {
    x: rect.left + rect.width / 2,
    y: rect.bottom - 8,
    width: rect.width
  };
}
</script>

<template>
  <header>
    <div class="nav-bar">
      <SvgGenoch />
      <NuxtLink
        to="/user/profile"
        @mouseover="() => showProfileOptions(true)"
        @mouseleave="() => showProfileOptions(false)"
      >
        <div ref="profileButton" class="profile">
          <img v-if="user?.profileUrl" :src="user.profileUrl">
          <SvgProfile v-else />
          <span>{{ user?.displayName ?? 'Unknown User' }}</span>
        </div>
      </NuxtLink>

      <Popup
        v-if="profileButtonRect"
        :x="profileButtonRect.x"
        :y="profileButtonRect.y"
      >
        <ul
          class="profile-quick-options"
          :style="{ width: `${profileButtonRect.width}px` }"
          @mouseover="() => showProfileOptions(true)"
          @mouseleave="() => showProfileOptions(false)"
        >
          <li>
            <NuxtLink to="/user/profile">
              Profile
            </NuxtLink>
          </li>
          <li>
            <button @click="() => { userStore.signOut() }">
              Sign out
            </button>
          </li>
        </ul>
      </Popup>
    </div>
  </header>
</template>

<style scoped>
header {
  @apply pl-[v-bind(offset)] bg-white box-border shadow-md;
}
.nav-bar {
  @apply flex flex-row items-center justify-between px-8 py-4;
}
.nav-bar .profile {
  @apply relative z-50;
  @apply flex flex-row items-center justify-center space-x-2;
  @apply btn btn-sm shadow-none;
}
.nav-bar .profile > svg, .nav-bar .profile > img {
  @apply w-6 h-6 aspect-square rounded-full;
}
.profile-quick-options {
  @apply flex flex-col gap-2 p-3 pt-6 bg-gray-50 rounded-b-lg shadow-md;
}
.profile-quick-options button, .profile-quick-options a {
  @apply block w-full p-2 rounded-lg text-text-dark text-left text-sm font-medium;
  @apply hover:bg-secondary-500;
}
</style>
