<template>
  <svg
    width="200px"
    height="36px"
    viewBox="0 0 200 36"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g id="Logos" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="Wide" transform="translate(0.478750, 0.092500)" fill="#2B2C41" fill-rule="nonzero">
        <g id="OCH" transform="translate(87.000000, 0.000000)">
          <path id="Shape" d="M17.673,34.215 C15.1405,34.215 12.797,33.786375 10.6425,32.929125 C8.488,32.071875 6.616625,30.87075 5.028375,29.32575 C3.440125,27.78075 2.205,25.972875 1.323,23.902125 C0.441,21.831375 0,19.5665 0,17.1075 C0,14.6485 0.441,12.383625 1.323,10.312875 C2.205,8.242125 3.44125,6.432875 5.03175,4.885125 C6.62225,3.337375 8.491625,2.13625 10.639875,1.28175 C12.788125,0.42725 15.1305,0 17.667,0 C20.2035,0 22.543125,0.425625 24.685875,1.276875 C26.828625,2.128125 28.693375,3.32475 30.280125,4.86675 C31.866875,6.40875 33.101375,8.21825 33.983625,10.29525 C34.865875,12.37225 35.307,14.644 35.307,17.1105 C35.307,19.577 34.865875,21.84775 33.983625,23.92275 C33.101375,25.99775 31.866875,27.80625 30.280125,29.34825 C28.693375,30.89025 26.828625,32.086875 24.685875,32.938125 C22.543125,33.789375 20.2055,34.215 17.673,34.215 Z M17.667,31.0455 C19.681,31.0455 21.544625,30.6985 23.257875,30.0045 C24.971125,29.3105 26.463375,28.332 27.734625,27.069 C29.005875,25.806 29.995125,24.33075 30.702375,22.64325 C31.409625,20.95575 31.76325,19.10775 31.76325,17.09925 C31.76325,15.09075 31.410625,13.24625 30.705375,11.56575 C30.000125,9.88525 29.0115,8.413 27.7395,7.149 C26.4675,5.885 24.97425,4.90575 23.25975,4.21125 C21.54525,3.51675 19.681,3.1695 17.667,3.1695 C15.653,3.1695 13.786625,3.517125 12.067875,4.212375 C10.349125,4.907625 8.852125,5.88775 7.576875,7.15275 C6.301625,8.41775 5.310625,9.89125 4.603875,11.57325 C3.897125,13.25525 3.54375,15.097 3.54375,17.0985 C3.54375,19.1 3.89675,20.944625 4.60275,22.632375 C5.30875,24.320125 6.298625,25.798625 7.572375,27.067875 C8.846125,28.337125 10.3435,29.317375 12.0645,30.008625 C13.7855,30.699875 15.653,31.0455 17.667,31.0455 Z" />
          <path id="Path" d="M60.8492433,34.215 C58.3307433,34.215 56.0008683,33.791 53.8596183,32.943 C51.7183683,32.095 49.8581183,30.898375 48.2788683,29.353125 C46.6996183,27.807875 45.4689933,25.99775 44.5869933,23.92275 C43.7049933,21.84775 43.2639933,19.576 43.2639933,17.1075 C43.2639933,14.639 43.7049933,12.36725 44.5869933,10.29225 C45.4689933,8.21725 46.7041183,6.407125 48.2923683,4.861875 C49.8806183,3.316625 51.7453683,2.12 53.8866183,1.272 C56.0278683,0.424 58.3577433,0 60.8762433,0 C63.3382433,0 65.6337433,0.411 67.7627433,1.233 C69.8917433,2.055 71.7034933,3.28575 73.1979933,4.92525 L70.9359933,7.1745 C69.5629933,5.775 68.0414933,4.757625 66.3714933,4.122375 C64.7014933,3.487125 62.9152433,3.1695 61.0127433,3.1695 C58.9807433,3.1695 57.0996183,3.518625 55.3693683,4.216875 C53.6391183,4.915125 52.1332433,5.8925 50.8517433,7.149 C49.5702433,8.4055 48.5758683,9.879375 47.8686183,11.570625 C47.1613683,13.261875 46.8077433,15.1075 46.8077433,17.1075 C46.8077433,19.1075 47.1613683,20.953125 47.8686183,22.644375 C48.5758683,24.335625 49.5702433,25.8095 50.8517433,27.066 C52.1332433,28.3225 53.6391183,29.299875 55.3693683,29.998125 C57.0996183,30.696375 58.9807433,31.0455 61.0127433,31.0455 C62.9152433,31.0455 64.7014933,30.724375 66.3714933,30.082125 C68.0414933,29.439875 69.5629933,28.41 70.9359933,26.9925 L73.1979933,29.24175 C71.7034933,30.88125 69.8917433,32.12 67.7627433,32.958 C65.6337433,33.796 63.3292433,34.215 60.8492433,34.215 Z" />
          <path id="Shape" d="M108.323275,0.3075 L111.839275,0.3075 L111.839275,33.9075 L108.323275,33.9075 L108.323275,0.3075 Z M87.2970245,33.9075 L83.7802745,33.9075 L83.7802745,0.3075 L87.2970245,0.3075 L87.2970245,33.9075 Z M108.672025,18.38775 L86.9212745,18.38775 L86.9212745,15.29925 L108.672025,15.29925 L108.672025,18.38775 Z" />
        </g>
        <g id="GEN" transform="translate(0.000000, 1.000000)">
          <path id="Shape" d="M17.72925,34.215 C15.17875,34.215 12.824875,33.791 10.667625,32.943 C8.510375,32.095 6.634125,30.898375 5.038875,29.353125 C3.443625,27.807875 2.205,25.99775 1.323,23.92275 C0.441,21.84775 0,19.576 0,17.1075 C0,14.639 0.441,12.36725 1.323,10.29225 C2.205,8.21725 3.448125,6.407125 5.052375,4.861875 C6.656625,3.316625 8.537375,2.12 10.694625,1.272 C12.851875,0.424 15.215,0 17.784,0 C20.328,0 22.667875,0.4065 24.803625,1.2195 C26.939375,2.0325 28.76375,3.2495 30.27675,4.8705 L28.06875,7.1205 C26.64125,5.721 25.084625,4.712625 23.398875,4.095375 C21.713125,3.478125 19.88675,3.1695 17.91975,3.1695 C15.85575,3.1695 13.946125,3.518625 12.190875,4.216875 C10.435625,4.915125 8.90925,5.8925 7.61175,7.149 C6.31425,8.4055 5.311875,9.879375 4.604625,11.570625 C3.897375,13.261875 3.54375,15.1075 3.54375,17.1075 C3.54375,19.0895 3.897375,20.926125 4.604625,22.617375 C5.311875,24.308625 6.31425,25.787 7.61175,27.0525 C8.90925,28.318 10.431125,29.299875 12.177375,29.998125 C13.923625,30.696375 15.82875,31.0455 17.89275,31.0455 C19.78675,31.0455 21.59275,30.752625 23.31075,30.166875 C25.02875,29.581125 26.6055,28.60675 28.041,27.24375 L30.0585,29.922 C28.4735,31.344 26.604,32.415 24.45,33.135 C22.296,33.855 20.05575,34.215 17.72925,34.215 Z M26.6715,29.514 L26.6715,17.09025 L30.0585,17.09025 L30.0585,29.922 L26.6715,29.514 Z" />
          <path id="Shape" d="M46.2731027,15.327 L64.0346027,15.327 L64.0346027,18.36 L46.2731027,18.36 L46.2731027,15.327 Z M46.6218527,30.81975 L66.8336027,30.81975 L66.8336027,33.9075 L43.1051027,33.9075 L43.1051027,0.3075 L66.1406027,0.3075 L66.1406027,3.39525 L46.6218527,3.39525 L46.6218527,30.81975 Z" />
          <polygon id="Path" points="78.2235 33.9075 78.2235 0.3075 81.13425 0.3075 104.30025 29.5125 102.7665 29.5125 102.7665 0.3075 106.2825 0.3075 106.2825 33.9075 103.3725 33.9075 80.20575 4.7025 81.74025 4.7025 81.74025 33.9075" />
        </g>
      </g>
    </g>
  </svg>
</template>
